<template>
  <div id="app">
    <div class="content">
      <my-header />
      <router-view/>
    </div>
    <my-footer class="footer" />
  </div>
</template>

<script>
import MyHeader from '@/components/Header.vue';
import MyFooter from '@/components/Footer.vue';

export default {
  components: {
    MyHeader,
    MyFooter,
  },
};
</script>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.required {
  &::before {
    content: '*';
    color: #672D9E;
    padding: 0px 4px;
    line-height: 100%;
  }
}
</style>

<style lang="scss" scoped>
#app {
  overflow-x: hidden;
  >.content {
    min-height: 100vh;
    position: relative;
  }
}
</style>
